
:root {

    --color_primario: #0075bb;
    
    --color_primario-hover: #0eb6a5;

    --color_secundario: #00a5d3;

    --color_terciario: #006e8c;

    --color_oscuro: #1f1e1e;

    --color_letra: #ffffff;

    

    --color_boton_primario: #e0e0e0;

    /* 
    --secundario: #f05454;

    --secundarioOscuro: #ec2222;

    --blanco: #FFF; */

    --color_blanco: #FFF;


    /* Fuentes */

    --color_fuentePrincipal: 'Montserrat', sans-serif;

}